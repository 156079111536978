* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font: inherit;
  vertical-align: baseline;
  line-height: 1.4;
}

html {
  font-size: 62.5%;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #312f2d;
  text-rendering: optimizelegibility;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

a {
  text-decoration: none;
  border: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

strong,
b {
  font-weight: 700;
}

em {
  font-style: italic;
}

button,
input[type="submit"] {
  cursor: pointer;
  border: 0;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
}

input,
textarea,
select {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
}

input.incorrect,
input.error,
textarea.incorrect,
textarea.error,
select.incorrect,
select.error {
  border-color: red;
}

input.correct,
input.success,
textarea.correct,
textarea.success,
select.correct,
select.success {
  border-color: green;
}

input:invalid,
textarea:invalid,
select:invalid {
  box-shadow: none;
}

input[type="search"] {
  appearance: none;
}

textarea {
  resize: vertical;
}

iframe {
  position: relative;
}

.header,
.main,
.footer {
  width: 100%;
  display: block;
  clear: both;
}

.form-control {
  width: 100%;
  height: 40px;
  display: block;
  padding: 0 20px;
  font-style: italic;
  border: 1px solid #312f2d;
  border-radius: 6px;
  color: #312f2d;
  line-height: 40px;
}

.form-control::-webkit-input-placeholder {
  color: #312f2d;
  opacity: 1;
}

.form-control:-moz-placeholder {
  color: #312f2d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #312f2d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #312f2d;
  opacity: 1;
}

textarea.form-control {
  height: auto;
  padding: 20px;
  line-height: 20px;
}

.form-search {
  position: relative;
  width: 300px;
  float: left;
}

.form-search.filter {
  width: 100%;
  float: none;
}

.form-search input {
  width: 100%;
  height: 45px;
  padding: 0 55px 0 15px;
  border-radius: 10px;
  border: 1px solid #e00519;
  font-style: italic;
}

.form-search input::-webkit-input-placeholder {
  color: #312f2d;
  opacity: 1;
}

.form-search input:-moz-placeholder {
  color: #312f2d;
  opacity: 1;
}

.form-search input::-moz-placeholder {
  color: #312f2d;
  opacity: 1;
}

.form-search input:-ms-input-placeholder {
  color: #312f2d;
  opacity: 1;
}

.form-search button {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  background: #e00519;
  color: #fff;
  font-size: 22px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.form-search button:hover,
.form-search button:focus {
  background: #c70416;
}

.form-newsletter {
  position: relative;
}

.form-newsletter input {
  width: 100%;
  height: 45px;
  display: block;
  padding: 0 140px 0 15px;
  border: 1px solid #e00519;
  background: #302f2b;
  font-style: italic;
  color: #fff;
}

.form-newsletter input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.form-newsletter input:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.form-newsletter input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.form-newsletter input:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.form-newsletter button {
  background: #e00519;
  color: #002540;
  text-transform: uppercase;
}

.form-newsletter button:hover,
.form-newsletter button:focus {
  background: #c70416;
}

.form-organize {
  width: 100%;
}

.form-organize label {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  color: #626262;
}

.form-organize select {
  width: calc(100% - 115px);
  height: 45px;
  padding: 0 15px;
  font-style: italic;
  border: 1px solid #e00519;
  border-radius: 10px;
  color: #626262;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  *zoom: 1;
}

.container:before,
.container:after {
  content: "";
  display: table;
}

.container:after {
  clear: both;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  *zoom: 1;
}

.container-fluid:before,
.container-fluid:after {
  content: "";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
  *zoom: 1;
}

.row:before,
.row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-8 {
  width: 66.66667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.66667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.33333%;
}

.col-xs-pull-5 {
  right: 41.66667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.33333%;
}

.col-xs-pull-8 {
  right: 66.66667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.33333%;
}

.col-xs-pull-11 {
  right: 91.66667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.66667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.33333%;
}

.col-xs-push-5 {
  left: 41.66667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.33333%;
}

.col-xs-push-8 {
  left: 66.66667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.33333%;
}

.col-xs-push-11 {
  left: 91.66667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  visibility: hidden;
}

.modal.open {
  visibility: visible;
}

.modal.open .modal-overlay {
  opacity: 1;
}

.modal.open .modal-box {
  transform: translate(-50%, -50%) scale(1);
}

.modal .modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  opacity: 0;
  background: #000;
  background: rgba(0,0,0,0.7);
}

.modal .modal-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
  max-width: 550px;
  width: 90%;
  max-height: 90%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px -15px #000;
  border: 1px solid #e6e6e6;
  color: #000;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.modal .modal-header {
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid #e6e6e6;
}

.modal .modal-header .modal-title {
  padding: 0 40px 0 15px;
  font-size: 22px;
  font-weight: 700;
}

.modal .modal-content {
  padding: 15px;
  font-size: 16px;
  overflow-y: auto;
}

.modal .modal-close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: none;
}

.pagination {
  display: table;
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
}

.pagination li {
  display: table-cell;
  vertical-align: middle;
  list-style: none;
  border: 1px solid #302f2b;
}

.pagination li:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination li:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination li.active a {
  background: #e00519;
  color: #fff;
}

.pagination li.disabled a {
  pointer-events: none;
  background: #eee;
}

.pagination a {
  display: block;
  padding: 8px 16px;
  color: #302f2b;
}

.pagination a:hover,
.pagination a:focus {
  background: #e00519;
  color: #fff;
}

.title-default {
  font-size: 36px;
  font-weight: 700;
  color: #312f2d;
  text-transform: uppercase;
}

.title-border {
  position: relative;
  padding-bottom: 20px;
  font-size: 38px;
  line-height: 40px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.title-border.border-0:after {
  background: #fff;
}

.title-border.border-1:after {
  background: #302f2b;
}

.title-border.border-2:after {
  background: #e00519;
}

.title-border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 20%;
  height: 4px;
  transform: translateX(-50%);
  background: #312f2d;
}

.text-lg {
  font-size: 20px;
}

.text-sm {
  font-size: 18px;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff;
}

.hero-slider {
  position: relative;
  border-bottom: 15px solid #e00519;
}

.hero-slider-holding {
  position: relative;
}

.hero-slider-stage {
  position: relative;
  height: 535px;
}

.hero-slider-stage div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
  background-size: cover;
  background-position: center center;
  z-index: 5;
}

.hero-slider-stage div.ativo {
  visibility: visible;
  opacity: 1;
}

.hero-slider-stage .container {
  position: relative;
  height: 100%;
  z-index: 5;
}

.hero-slider-stage .content {
  position: relative;
  top: 50%;
  width: 100%;
  max-width: 650px;
  transform: translateY(-50%);
  color: #fff;
}

.hero-slider-stage p {
  position: relative;
  font-weight: 700;
  font-size: 42px;
  line-height: 45px;
  text-transform: uppercase;
}

.hero-slider-nav .hero-slider-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 6px 18px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 40px;
  line-height: 40px;
  border-radius: 10px;
  transition: all 0.2s ease;
  z-index: 5;
}

.hero-slider-nav .hero-slider-navigation.to-left {
  left: 10%;
}

.hero-slider-nav .hero-slider-navigation.to-right {
  right: 10%;
}

.hero-slider-nav .hero-slider-navigation:hover,
.hero-slider-nav .hero-slider-navigation:focus {
  background: #fff;
  color: #312f2d;
}

.hero-slider-bullets {
  position: absolute;
  width: 100%;
  bottom: 50px;
  left: 0;
  text-align: center;
  z-index: 15;
}

.hero-slider-bullets>a {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  margin: 0 4px;
  background: #fff;
}

.hero-slider-bullets>a.ativo {
  background: #ccc;
}

.hero-slider-bullets>a:hover,
.hero-slider-bullets>a:focus {
  background: #ccc;
}

.card-visual {
  position: relative;
  height: 290px;
}

.card-visual>a {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #e00519;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.card-visual>a:hover .card-visual-image,
.card-visual>a:focus .card-visual-image {
  transform: scale(1.2);
}

.card-visual .card-visual-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s ease;
}

.card-visual .card-visual-content {
  position: relative;
  padding: 30px 20px 0 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
}

.card-visual .card-visual-content span {
  font-size: 30px;
  line-height: 32px;
}

.card-post {
  position: relative;
}

.card-post .card-post-image {
  height: 280px;
  margin-bottom: 15px;
}

.card-post .card-post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.card-post .card-post-content .card-post-meta {
  margin-bottom: 15px;
}

.card-post .card-post-content .card-post-meta .card-post-category {
  font-weight: 700;
  color: #626262;
}

.card-post .card-post-content .card-post-meta .card-post-category+.card-post-date:before {
  content: "| ";
  margin: 0 4px;
}

.card-post .card-post-content .card-post-title {
  margin-bottom: 15px;
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}

.card-post .card-post-content .card-post-excerpt {
  color: #626262;
  margin-bottom: 15px;
}

.card-post-vertical {
  margin-bottom: 30px;
}

.card-post-vertical .card-post-vertical-image {
  height: 225px;
}

.card-post-vertical .card-post-vertical-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-meta {
  margin-bottom: 5px;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-meta .card-post-vertical-category {
  font-weight: 700;
  color: #626262;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-meta .card-post-vertical-category+.card-post-vertical-date:before {
  content: "| ";
  margin: 0 4px;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-title {
  margin-bottom: 5px;
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}

.card-post-vertical .card-post-vertical-content .card-post-vertical-excerpt {
  color: #626262;
  margin-bottom: 10px;
}

.btn {
  display: inline-block;
  padding: 6px 16px;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  background: #312f2d;
  transition: all 0.1s ease;
}

.btn.btn-danger {
  background: #e00519;
}

.btn.btn-danger:hover,
.btn.btn-danger:focus {
  background: #ae0413;
}

.btn.btn-grey {
  background: #676767;
}

.btn.btn-grey:hover,
.btn.btn-grey:focus {
  background: #4e4e4e;
}

.btn.btn-facebook {
  background: #3b5998;
}

.btn.btn-facebook:hover,
.btn.btn-facebook:focus {
  background: #2d4373;
}

.btn.btn-twitter {
  background: #55acee;
}

.btn.btn-twitter:hover,
.btn.btn-twitter:focus {
  background: #2795e9;
}

.btn.btn-pinterest {
  background: #ca2728;
}

.btn.btn-pinterest:hover,
.btn.btn-pinterest:focus {
  background: #9f1f20;
}

.btn.btn-google-plus {
  background: #dd4b39;
}

.btn.btn-google-plus:hover,
.btn.btn-google-plus:focus {
  background: #c23321;
}

.btn.btn-email {
  background: #307ab7;
}

.btn.btn-email:hover,
.btn.btn-email:focus {
  background: #255f8f;
}

.btn.btn-lg {
  text-transform: uppercase;
  padding: 9px 30px;
}

.btn.btn-block {
  width: 100%;
  display: block;
}

.page-header {
  position: relative;
  height: 270px;
  background-color: #e00519;
  background-size: cover;
  background-position: center center;
}

.page-header .page-header-inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.page-header .page-header-title {
  position: relative;
  padding-bottom: 20px;
  font-size: 38px;
  line-height: 40px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.page-header .page-header-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 20%;
  height: 4px;
  transform: translateX(-50%);
  background: #fff;
}

.page-return {
  padding: 100px 0;
  text-align: center;
  background: #eaeaea;
}

.page-return .page-return-title {
  font-size: 80px;
  font-weight: 700;
  color: #e00519;
  text-transform: uppercase;
}

.page-return .page-return-subtitle {
  margin-bottom: 20px;
  font-size: 24px;
}

.page-return .page-return-description {
  margin-bottom: 20px;
}

.carousel-partners .owl-item {
  position: relative;
  height: 80px;
  text-align: center;
}

.carousel-partners .owl-item>div {
  height: 100%;
}

.carousel-partners .owl-item>div img {
  position: relative;
  top: 50%;
  width: auto;
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
  transform: translateY(-50%);
}

.carousel-partners .owl-controls {
  margin-top: 50px;
}

.carousel-partners .owl-controls .owl-dots {
  text-align: center;
}

.carousel-partners .owl-controls .owl-dots>div {
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #fff;
  border-radius: 100%;
  margin: 0 4px;
}

.carousel-partners .owl-controls .owl-dots>div.active {
  background: #ccc;
}

.carousel-partners .owl-controls .owl-dots>div:hover,
.carousel-partners .owl-controls .owl-dots>div:focus {
  background: #ccc;
}

.carousel-product .owl-item>div {
  position: relative;
  height: 80px;
}

.carousel-product .owl-item>div>a {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #e00519;
  transition: all 0.2s ease;
}

.carousel-product .owl-item>div>a:hover,
.carousel-product .owl-item>div>a:focus {
  opacity: 0.7;
}

.carousel-product .owl-item>div>a.active {
  box-shadow: 0 0 4px 0 #e00519 inset;
}

.carousel-product .owl-item>div img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 90%;
  max-height: 90%;
  width: auto;
  display: block;
  margin: 0 auto;
}

.carousel-product .owl-controls {
  margin-top: 20px;
}

.carousel-product .owl-controls .owl-dots {
  text-align: center;
}

.carousel-product .owl-controls .owl-dots>div {
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #ccc;
  border-radius: 100%;
  margin: 0 4px;
}

.carousel-product .owl-controls .owl-dots>div.active {
  background: #e00519;
}

.carousel-product .owl-controls .owl-dots>div:hover,
.carousel-product .owl-controls .owl-dots>div:focus {
  background: #e00519;
}

.navigation-inner {
  margin-bottom: 20px;
}

.navigation-inner .navigation-inner-trigger {
  display: block;
  margin-bottom: 15px;
  color: #e00519;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  transition: all 0.2s ease;
}

.navigation-inner .navigation-inner-trigger:hover,
.navigation-inner .navigation-inner-trigger:focus {
  color: #c70416;
}

.navigation-inner .navigation-inner-trigger.active {
  color: #000;
  pointer-events: none;
}

.navigation-inner .navigation-inner-list {
  display: none;
}

.navigation-inner .navigation-inner-list li {
  position: relative;
  margin-bottom: 5px;
  list-style: none;
}

.navigation-inner .navigation-inner-list li:before {
  content: "\f105";
  font-family: 'FontAwesome';
  color: #e00519;
  margin-right: 8px;
}

.navigation-inner .navigation-inner-list a {
  color: #312f2d;
}

.navigation-inner .navigation-inner-list a:hover,
.navigation-inner .navigation-inner-list a:focus {
  color: #e00519;
}

.google-maps {
  position: relative;
  width: 100%;
  height: 400px;
  vertical-align: middle;
}

.list-social {
  display: inline-block;
}

.list-social li {
  display: inline-block;
  list-style: none;
}

.list-social li a {
  width: 30px;
  height: 30px;
  display: block;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  border-radius: 100%;
  background: #fff;
  color: #e00519;
  transition: all 0.2s ease;
}

.list-social li a:hover,
.list-social li a:focus {
  transform: scale(1.1);
}

.list-social li i {
  vertical-align: middle;
}

.list-team li {
  list-style: none;
}

.list-team .team-item .team-item-avatar {
  width: 175px;
  height: 175px;
  margin: 0 auto 10px;
  background: #fff;
  border: 12px solid #e00519;
  border-radius: 100%;
  overflow: hidden;
}

.list-team .team-item .team-item-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.list-team .team-item .team-item-name {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
}

.list-sitemap li {
  list-style: none;
}

.list-sitemap li:first-child a {
  padding-top: 0;
}

.list-sitemap li+li {
  border-top: 1px solid #312f2d;
}

.list-sitemap a {
  display: block;
  padding: 15px 0;
  color: #626262;
}

.list-sitemap a:hover,
.list-sitemap a:focus {
  color: #e00519;
}

.list-credits li {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
  list-style: none;
}

.list-share li {
  margin: 0 5px 10px 0;
  display: inline-block;
  list-style: none;
}

.list-share a {
  padding: 10px;
  text-align: center;
  min-width: 50px;
}

.list-share a span {
  margin-left: 6px;
}

.logo {
  float: left;
  padding: 15px 0;
}

.logo a {
  display: block;
}

.header-information {
  float: right;
  padding: 28.5px 0;
}

.header-information .header-information-text {
  float: left;
  text-align: right;
  margin-left: 25px;
}

.navigation {
  position: relative;
  background: #302f2b;
}

.navigation .menu {
  text-align: center;
}

.navigation .menu .menu-item {
  position: relative;
  display: inline-block;
  list-style: none;
}

.navigation .menu .menu-item.active>a {
  color: #e00519;
}

.navigation .menu .menu-item>a {
  display: block;
  padding: 8px 30px;
  color: #fff;
  text-transform: uppercase;
}

.navigation .menu .menu-item>a:hover,
.navigation .menu .menu-item>a:focus {
  color: #e00519;
}

.navigation .menu .menu-item .menu-item-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 15px;
  background: #fff;
  box-shadow: 0px 0px 7px -3px #000;
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  transform: translateY(-15px);
  z-index: 15;
}

.navigation .menu .menu-item .menu-item-dropdown:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  height: 0;
  width: 0;
  margin-left: -12px;
  pointer-events: none;
  border: solid transparent;
  border-color: rgba(255,255,255,0);
  border-bottom-color: #fff;
  border-width: 12px;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item {
  list-style: none;
  position: relative;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item+.dropdown-item {
  margin-top: 10px;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item>a {
  text-transform: uppercase;
  color: #302f2b;
  font-weight: 700;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item>a:hover,
.navigation .menu .menu-item .menu-item-dropdown .dropdown-item>a:focus {
  color: #e00519;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item>ul {
  display: none;
  position: absolute;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item:hover>ul {
  display: block;
  left: 100%;
  min-width: 220px;
  margin-top: -43px;
  position: absolute;
  background: #fff;
  border-radius: 6px;
  z-index: 12;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item:hover>ul>li {
  list-style: none;
  font-size: 15px;
  float: none;
  padding: 5px;
  display: block;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item:hover>ul>li:hover {
  font-size: 17px;
  font-weight: 700;
}

.navigation .menu .menu-item .menu-item-dropdown .dropdown-item:hover>ul>li>a {
  color: #000;
}

.btn-hamburguer {
  width: 100%;
  display: block;
  padding: 12px 0;
  color: #fff;
  font-size: 26px;
  text-align: center;
  background: none;
  cursor: pointer;
}

.change-language {
  padding: 9px;
}

.change-language img {
  vertical-align: baseline;
}

.footer .footer-information {
  padding: 60px 0 30px;
}

.footer .footer-information .footer-information-featured {
  padding: 30px 25px;
  border-radius: 6px;
  background: #e00519;
  color: #fff;
}

.footer .footer-information .footer-information-featured p {
  margin-bottom: 25px;
}

.footer .footer-information .footer-information-featured p+p {
  padding-top: 25px;
  border-top: 1px solid #fff;
}

.footer .footer-information .footer-heading {
  font-weight: 700;
  text-transform: uppercase;
  color: #002540;
}

.footer .footer-information .footer-anchor {
  font-weight: 700;
  text-transform: uppercase;
  color: #002540;
}

.footer .footer-information .footer-anchor:hover,
.footer .footer-information .footer-anchor:focus {
  color: #e00519;
}

.footer .footer-copyright {
  padding: 15px 0;
  background: #302f2b;
  font-size: 14px;
}

.footer .footer-copyright .footer-copyright-disclaimer {
  padding: 3px 0;
  color: #fff;
  font-size: 14px;
}

.section-default {
  padding: 60px 0 30px;
}

.section-default.featured {
  background: #eaeaea;
}

.section-partners {
  padding: 60px 0;
  background: #e00519;
}

::selection {
  background-color: #d1d3d4;
  color: #2d2a2b;
}

.newsletter {
  padding: 30px 0;
  background: #302f2b;
  color: #fff;
}

.newsletter .newsletter-text {
  text-transform: uppercase;
  text-align: right;
  font-size: 18px;
  line-height: 24px;
}

.about .about-title {
  font-weight: 700;
}

.about .about-title span {
  display: block;
  font-size: 32px;
  line-height: 34px;
}

.about .about-title strong {
  font-size: 44px;
  line-height: 48px;
  font-weight: 900;
}

.about .about-image {
  text-align: center;
}

.about-gallery {
  width: 100%;
  height: 280px;
}

.about-gallery a {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 6px;
  overflow: hidden;
}

.about-gallery a:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: #e00519;
  background: rgba(224,5,25,0.7);
  transition: all 0.2s ease;
}

.about-gallery a:hover img,
.about-gallery a:focus img {
  transform: scale(1.2);
}

.about-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.product-ajax {
  min-height: 300px;
}

.product-ajax .product-loading {
  padding-top: 40px;
  font-size: 0;
  text-align: center;
}

.product-ajax .product-loading:before {
  content: url(../img/loading.gif);
}

.product-item {
  position: relative;
}

.product-item>a {
  width: 100%;
  height: 100%;
  display: block;
  color: #312f2d;
}

.product-item>a:hover,
.product-item>a:focus {
  color: #e00519;
}

.product-item .product-item-image {
  width: 100%;
  height: 300px;
  text-align: center;
  overflow: hidden;
  border: 1px solid;
}

.product-item .product-item-image img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.product-item .product-item-content {
  padding: 15px;
  text-align: center;
}

.product-item .product-item-content .product-item-title {
  text-transform: uppercase;
  font-weight: 700;
}

.product .product-view-image {
  position: relative;
  width: 100%;
  height: 450px;
  margin-bottom: 20px;
  border: 1px solid #e00519;
  border-radius: 10px;
  overflow: hidden;
}

.product .product-view-image img {
  position: relative;
  top: 50%;
  display: block;
  margin: 0 auto;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 100%;
}

.product .product-view-title {
  padding-bottom: 15px;
  margin-bottom: 15px;
  color: #e00519;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 26px;
  border-bottom: 1px solid #e00519;
}

.product .product-view-description {
  margin-bottom: 15px;
  border-bottom: 1px solid #302f2b;
  font-size: 18px;
}

.product .product-view-description p {
  margin-bottom: 15px;
}

.product .product-view-meta {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #302f2b;
  color: #626262;
}

.product .product-view-meta span {
  margin-right: 10px;
}

.product .product-view-meta span+span:before {
  content: "| ";
  margin-right: 10px;
}

.post-view {
  position: relative;
  margin-bottom: 50px;
}

.post-view .post-view-head {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e00519;
}

.post-view .post-view-head .post-view-head-title {
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 700;
}

.post-view .post-view-head .post-view-head-meta span {
  vertical-align: middle;
  margin-right: 20px;
}

.post-view .post-view-head .post-view-head-date {
  color: #626262;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
}

.post-view .post-view-head .post-view-head-views {
  color: #626262;
  text-transform: lowercase;
  font-size: 14px;
}

.post-view .post-view-body {
  margin-bottom: 40px;
}

.post-view .post-view-body .post-view-body-image {
  margin-bottom: 40px;
}

.post-view .post-view-body .post-view-body-image img {
  border-radius: 10px;
}

.post-view .post-view-body .post-view-body-content p {
  margin-bottom: 20px;
}

.post-view .post-view-body .post-view-body-content a {
  color: #e00519;
}

.post-view .post-view-body .post-view-body-content ul li {
  list-style-position: inside;
}

.post-view .post-view-footer {
  padding-top: 40px;
  border-top: 1px solid #e00519;
}

.contact-information {
  padding: 30px 0 10px;
  background: #e00519;
  color: #fff;
}

.contact-information .contact-information-item {
  position: relative;
  height: 65px;
  padding-left: 65px;
}

.contact-information .contact-information-item .contact-information-item-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 50px;
  vertical-align: middle;
}

.contact-information .contact-information-item .contact-information-item-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.linha-carrinho {
  display: table;
  height: 200px;
}

.linha-carrinho-inside {
  padding: 0 10px;
  display: table-cell;
  vertical-align: middle;
}

.row-carrinho {
  border: 1px solid;
}

.image-carrinho img {
  border-right: 1px solid #312f2d;
  border-image: linear-gradient(to bottom, rgba(49,47,45,0) 25%, #312f2d 25%, #312f2d 75%, rgba(49,47,45,0) 75%);
  border-image-slice: 1;
}

.button-carrinho {
  float: right;
}

.div-btn-cart {
  position: relative;
  width: 50px;
  float: left;
}

.btn-cart {
  height: 45px;
  width: 45px;
  border: 1px solid #e00519;
  color: #e00519;
  background: none;
  font-size: 22px;
  border-radius: 10px;
}

.btn-cart:hover {
  color: #fff;
  background: #e00519;
}

.btn-cart:hover ~ .btn-cart[data-badge]:after {
  color: #e00519 !important;
  background: none !important;
}

.btn-cart[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: .7em;
  background: #e00519;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 1px 8px #333;
}

.breadcrumb {
  padding: 10px 0 10px 0;
}

.breadcrumb li {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  color: #e00519;
  font-size: 16px;
  font-size: 1.6rem;
}

.breadcrumb li+li:before {
  content: "/";
  margin: 0 4px;
}

.breadcrumb a {
  color: inherit;
}

.breadcrumb a:hover,
.breadcrumb a:focus {
  color: #c90416;
}

.md-25 {
  width: 20% !important;
}

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */

.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0,0,0,0.5);
  -moz-box-shadow: 0 10px 25px rgba(0,0,0,0.5);
  box-shadow: 0 10px 25px rgba(0,0,0,0.5);
}

.fancybox-outer,
.fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url("../img/fancybox/fancybox_sprite.png");
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../img/fancybox/fancybox_loading.gif") center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("../img/fancybox/blank.gif");
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("../img/fancybox/fancybox_overlay.png");
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  background: rgba(0,0,0,0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0,0,0,0.8);
}

@media screen and (min-width: 769px) {
  .form-newsletter input {
    border-radius: 6px;
  }

  .form-newsletter button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 35px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .card-post .card-post-content .card-post-title {
    height: 70px;
    overflow: hidden;
  }

  .card-post .card-post-content .card-post-excerpt {
    height: 90px;
    overflow: hidden;
  }

  .about .about-title span+span {
    text-indent: 60px;
  }

  .product-item .product-item-content .product-item-title {
    height: 65px;
    overflow: hidden;
  }
}

@media (min-width: 769px) {
  .container {
    width: 750px;
  }

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.66667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33333%;
  }

  .col-sm-5 {
    width: 41.66667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33333%;
  }

  .col-sm-8 {
    width: 66.66667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33333%;
  }

  .col-sm-11 {
    width: 91.66667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.66667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.33333%;
  }

  .col-sm-pull-5 {
    right: 41.66667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.33333%;
  }

  .col-sm-pull-8 {
    right: 66.66667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.33333%;
  }

  .col-sm-pull-11 {
    right: 91.66667%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.66667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.33333%;
  }

  .col-sm-push-5 {
    left: 41.66667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.33333%;
  }

  .col-sm-push-8 {
    left: 66.66667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.33333%;
  }

  .col-sm-push-11 {
    left: 91.66667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 993px) {
  .container {
    width: 970px;
  }

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.66667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33333%;
  }

  .col-md-5 {
    width: 41.66667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33333%;
  }

  .col-md-8 {
    width: 66.66667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33333%;
  }

  .col-md-11 {
    width: 91.66667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.66667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.33333%;
  }

  .col-md-pull-5 {
    right: 41.66667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.33333%;
  }

  .col-md-pull-8 {
    right: 66.66667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.33333%;
  }

  .col-md-pull-11 {
    right: 91.66667%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.66667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.33333%;
  }

  .col-md-push-5 {
    left: 41.66667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.33333%;
  }

  .col-md-push-8 {
    left: 66.66667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.33333%;
  }

  .col-md-push-11 {
    left: 91.66667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 993px) {
  .list-credits {
    text-align: right;
  }

  .navigation .menu {
    display: block !important;
  }

  .navigation .menu .menu-item:hover .menu-item-dropdown,
  .navigation .menu .menu-item:focus .menu-item-dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .btn-hamburguer {
    display: none;
  }
}

@media (min-width: 1201px) {
  .container {
    width: 1170px;
  }

  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.66667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.33333%;
  }

  .col-lg-5 {
    width: 41.66667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.33333%;
  }

  .col-lg-8 {
    width: 66.66667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.33333%;
  }

  .col-lg-11 {
    width: 91.66667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.66667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.33333%;
  }

  .col-lg-pull-5 {
    right: 41.66667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.33333%;
  }

  .col-lg-pull-8 {
    right: 66.66667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.33333%;
  }

  .col-lg-pull-11 {
    right: 91.66667%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.66667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.33333%;
  }

  .col-lg-push-5 {
    left: 41.66667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.33333%;
  }

  .col-lg-push-8 {
    left: 66.66667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.33333%;
  }

  .col-lg-push-11 {
    left: 91.66667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (max-width: 1600px) {
  .hero-slider-nav .hero-slider-navigation.to-left {
    left: 15px;
  }

  .hero-slider-nav .hero-slider-navigation.to-right {
    right: 15px;
  }
}

@media screen and (max-width: 1400px) {
  .hero-slider-stage .content {
    max-width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
  .hero-slider-stage p {
    font-size: 30px;
    line-height: 34px;
  }

  .hero-slider-nav {
    display: none;
  }

  .navigation .menu .menu-item>a {
    padding: 8px 20px;
  }
}

@media screen and (max-width: 992px) {
  .hero-slider-stage {
    height: 340px;
  }

  .hero-slider-bullets {
    bottom: 30px;
  }

  .card-visual .card-visual-image img {
    width: 100%;
    opacity: 0.7;
  }

  .navigation .menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: none;
    background: #302f2b;
    z-index: 100;
  }

  .navigation .menu .menu-item {
    width: 100%;
    display: block;
  }

  .navigation .menu .menu-item>a {
    padding: 12px 5px;
  }

  .footer .footer-copyright {
    text-align: center;
  }

  .footer .footer-copyright .footer-copyright-disclaimer {
    padding: 0 0 25px 0;
  }

  .newsletter .newsletter-text {
    margin-bottom: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .form-search {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    float: none;
  }

  .form-search.filter {
    max-width: 100%;
  }

  .form-newsletter button {
    display: block;
    width: 100%;
    padding: 10px;
  }

  .title-default {
    font-size: 28px;
    line-height: 34px;
  }

  .hero-slider-stage {
    height: 300px;
  }

  .hero-slider-stage .content {
    transform: translateY(-75%);
  }

  .hero-slider-stage p {
    font-size: 22px;
    line-height: 24px;
  }

  .page-header .page-header-title {
    font-size: 28px;
    line-height: 32px;
  }

  .carousel-partners .owl-item img {
    max-width: 60%;
    display: block;
    margin: 0 auto;
  }

  .google-maps {
    height: 280px;
  }

  .logo {
    width: 100%;
    padding: 30px 0 15px;
    text-align: center;
    float: none;
  }

  .header-information {
    float: none;
    text-align: center;
    margin-left: -25px;
  }

  .header-information strong {
    padding: 15px 0 30px;
    float: none;
  }

  .header-information .header-information-text {
    float: none;
    text-align: center;
  }

  .header-information .header-information-text strong {
    display: none;
  }

  .about .about-title span {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
  }

  .about .about-title strong {
    font-size: 28px;
    line-height: 32px;
  }

  .post-view .post-view-head .post-view-head-title {
    font-size: 22px;
    line-height: 26px;
  }

  .post-view .post-view-head .post-view-head-meta span {
    display: block;
  }

  .contact-information .contact-information-item {
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
  }

  .md-25 {
    width: auto !important;
  }
}

@media screen and (max-width: 480px) {
  .title-default {
    font-size: 22px;
    line-height: 26px;
  }

  .hero-slider-stage p {
    font-size: 18px;
    line-height: 24px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url("../img/fancybox/fancybox_sprite@2x.png");
    background-size: 44px 152px;
  }

  #fancybox-loading div {
    background-image: url("../img/fancybox/fancybox_loading@2x.gif");
    background-size: 24px 24px;
  }
}